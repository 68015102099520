@font-face {
  font-family: "voyage-regular";
  src: url(./fonts/voyage-regular.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-danger-h: 10;
  --color-danger-s: 47%;
  --color-danger-l: 66%;
  --color-primary-h: 28;
  --color-primary-s: 43%;
  --color-primary-l: 85%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  --color-primary: hsl(
    var(--color-primary-h),
    var(--color-primary-s),
    var(--color-primary-l)
  );
  --color-secondary: hsl(
    var(--color-primary-h),
    var(--color-primary-s),
    calc(var(--color-primary-l) * 0.9)
  );
  --background-transition-duration: 5s;

  background: var(--color-primary);
  transition: var(--background-transition-duration) background linear;
  height: 100%;
}

body {
  font-family: "voyage-regular";
  font-size: 140%;
  line-height: 1;
  letter-spacing: 4px;
  text-align: center;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

svg {
  display: flex;
  overflow: visible;
}

#root {
  position: absolute;
  inset: 0;
}

.view {
  height: 100%;
  padding: 3vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80vw;
  flex: 1;
  text-align: center;
  /* Optical compensation */
  transform: translateY(-8%);
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.mole {
  flex: 1;
  max-width: 220px;
}

.interface {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.interface > * {
  flex: 1;
}

.interface__left {
  text-align: left;
}

.interface__right {
  text-align: right;
}

.btn {
  all: unset;
  cursor: pointer;
}

.result > * + * {
  margin-top: 40px;
}

/* Utils */

.pointer {
  cursor: pointer;
}

/* SVG Selectors */

.hole {
  fill: var(--color-secondary);
  transition: var(--background-transition-duration) fill linear;
}

.shadow {
  filter: blur(10px);
}

/* Responsive */

@media screen and (min-width: 768px) {
  body {
    font-size: 300%;
  }
  .frame {
    width: 80vw;
  }
}
